import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.jpg";
import quotationImage from "../Assets/Images/quotation.svg";

import analyzeIcon from '../Assets/Images/insights.svg';
import alignmentIcon from '../Assets/Images/align.svg';
import advanceIcon from '../Assets/Images/advance.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";
import ScheduleCallThumbnail from "../Assets/Images/video-thumb-cropped.jpg"

const Results = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember]   = useState(false);
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0, 0]);

	
	useEffect(() => { 
		document.body.style.backgroundColor = "#122845";
	},[]);

  useEffect(() => {
    fetchResults();
  }, []);

  // to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4, subScore5, subScore6])

  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setIsLoading(false);
          setName(data.name);
          setScore(data.score);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setSubScore6(data.subScore6);
          setIsMember(data.isTeamMember);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const showTitleText = () => {
    if (score > 75) {
      return <h1><span className="user-name">{name}</span>, it looks like your digital ministry strategy exceeds expectations.</h1>
    } else if (score > 50 && score <= 75) {
      return <h1><span className="user-name">{name}</span>, it looks like your digital ministry strategy meets expectations.</h1>
    } else if (score > 25 && score <= 50) {
      return <h1><span className="user-name">{name}</span>, it looks like your digital strategy needs improvement</h1>
    } else if (score >= 0 && score <= 25) {
      return <h1><span className="user-name">{name}</span>, it looks like your digital ministry strategy is not on track</h1>
    }
  };

  const showScoreText = () => {
    if (score > 75) {
      return <div className="result-text">
        <p>Congratulations! Based on your score, it looks like your digital ministry strategy exceeds expectations.</p>
        <p>It’s exciting to see leaders like you maximize your opportunities as a church!</p>
        <p>Churches all over the world are trying to figure this out and I believe that together we can help the Capital C church continue to thrive.</p>
        <p>I’d love to hear more about your church and how you are doing ministry, so click the button at the bottom of this page to schedule a free call with me and let’s talk! I look forward to connecting with you soon.</p>
      </div>
    } else if (score > 50 && score <= 75) {
      return <div className="result-text">
        <p>Based on your score, it looks like your digital strategy meets expectations. But, there’s definitely room to take it to the next level!</p>
        <p>By giving special attention to the areas you scored lower in, you can begin to engage in fruitful discussions about your church’s mission and align around a plan to strengthen your approach and leverage technology even more effectively in your ministry.</p>
      </div>
    } else if (score > 25 && score <= 50) {
      return <div className="result-text">
        <p>Based on your score, it looks like your digital strategy might need some improvement. But, don’t be discouraged!</p>
        <p>While this can seem like a challenge initially, focusing on the 6 critical areas in this assessment can help you strengthen your strategy and leverage technology effectively to transform even more lives for Christ.</p>
      </div>
    } else if (score >= 0 && score <= 25) {
      return <div className="result-text">
        <p>Based on your score, it looks like your digital ministry strategy might not be on track.</p>
        <p>While this could seem like a setback, please remember that significant growth and a meaningful digital ministry is just around the corner!</p>
        <p>By focusing on the 6 critical areas in this assessment, you can begin to strengthen your approach and leverage technology more effectively in your church and community.</p>
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this test</h3>
        <p>Invite your team member or a friend to take this test and help them discover where they are on the journey to leveraging technology effectively in ministry!</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="thumbnail"><img src={ScheduleCallThumbnail} alt="" srcSet="" /></div>
          <h2>Schedule a free call with Alan</h2>
          <p>Schedule a call with Alan to debrief your test results and take the next step towards supercharging your digital ministry strategy!</p>
          <div className="cta-btn">
            <a title="Calendly Digital Ministry Matrix" href={`https://calendly.com/reachalangeorge/digitalministrymatrix?utm_source=${id.trim()}`} target="_blank" rel="noreferrer">
              <Button>TALK TO ALAN</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Gain new insights into your team's alignment with your school board by inviting them to take this test!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE MY BOARD</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F7D54D" }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe2" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '25%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '25%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '25%' }  }></h4>
                <h4 className="eexpe1" style={{ minWidth: '25%' }}></h4>
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
             
              <div className="pervalue-text">
                {/* <p className="not-aligned" style={{ marginLeft: '9%', fontWeight: score <= 45 ? 800 : 600 }}>Not on<br />Track</p> */}
                <p className="not-aligned" style={{ marginLeft: '9%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Not on<br />Track</p>
                <p className="not-aligned" style={{ marginLeft: '29%', fontWeight: windowWidth >= 830 ? 400 : 700 }}>Needs<br />Improvement</p>
                <p className="poor-aligned" style={{ marginLeft: '55%', fontWeight: score > 20 && windowWidth >= 830 ? 400 : 700 }}>Meets<br />Expectations</p>
                <p className="some-aligned" style={{ marginLeft: '79%', fontWeight: score > 40 && windowWidth >= 830 ? 400 : 700 }}>Exceeds<br />Expectations</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                {/* <p className="high-aligned" style={{ marginLeft: '85%', fontWeight: score > 80 ? 800 : 600 }}>Highly<br />Aligned</p> */}
              </div>

              {/* 
              Needs<br />Improvement - 0-45
              Meets<br />Expectations - 45-70
              Exceeds<br />Expectations - 70-100
              */}

            </div>
          </div>
          <div className="spacer"></div>
          <div className="spacer"></div>

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {showScoreText()}
          <div className="score-section-container">

            <div className="score-section">
              <p className="score-txt">{subScores[0]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[0]} /></div>
              <p className="score-name">LEADERSHIP</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[1]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[1]} /></div>
              <p className="score-name">SYSTEMS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[2]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[2]} /></div>
              <p className="score-name">COMMUNICATION</p>
            </div>            

            <div className="score-section">
              <p className="score-txt">{subScores[3]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[3]} /></div>
              <p className="score-name">DATA</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[4]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[4]} /></div>
              <p className="score-name">VOLUNTEERS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[5]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[5]} /></div>
              <p className="score-name">COMMUNITY</p>
            </div>

          </div>
        </div>
      </div>

      {showInvite()}

      {showTeamMember()}

      {/* <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>This assessment helped us stop and assess whether or not we were utilizing our digital resources in the most effective way possible. It also gave our church team a clear next step to align around!<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Senior Pastor, Lifeway Church</span>
          </div>

        </div>

      </div> */}

      {showSchedule()}
      {/* {showLegacyInvite()} */}

      {/* </div> */}

    </>

  );
};
export default Results;
