import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

// import QuestionTemp from '../Components/QuestionsTemp';
import Question from '../Components/Questions';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import nextArrowWhite from '../Assets/Images/next-arrow-white.svg';

import '../App.scss';

/*let questions = [
  {
    id: 21,
    question: "How urgently do you want to ensure your church<br/>is equipped for future evangelism?",
    answer: 0,
    type: "slider"
  },
  {
    id: 22,
    question: "What's the biggest reason for your church to be prepared<br/>to reach out to the unchurched in your community?",
    answer: "",
    type: "text"
  },
  {
    id: 23,
    question: "What's the biggest hurdle that keeps your church from<br/>reaching out to more people?",
    answer: "",
    type: "text"
  }
]*/

const AssessmentAdditional = () => {

  let navigate      = useNavigate();
  const { id }      = useParams();
  const addCtx 	    = useContext(AdditionalQuestionContext);
  console.log(addCtx);

  useEffect(() => {
    document.body.style.backgroundColor = "#EEEEEE";
    getAdditional();
  }, []);
  

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [questions, setQuestions] = React.useState([]);
  const [questionCount, setQuestionCount] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [prevValue, setPrevValue] = React.useState(0);
  const [textInput, setTextInput] = React.useState("");
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  function getAdditional() {

    if(addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId              = process.env.REACT_APP_CLIENT_ID;
    
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status === "success") {
          setQuestions(data.questions);
          setQuestionCount(data.questions.length);
          addCtx.addQuestions(data.questions);
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });

    }

    

  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
			method: 'PUT',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				questions
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status === "success") {
        if(data.member === true) {
          navigate("/details/" + id + '?member=' + data.memberId, { replace: true });
        } else {
          navigate("/details/" + id, { replace: true });
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const prevQuestion = () => {
    if(activeStep === 0) {
      navigate("/assessment/" + id + '/review', { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
        addCtx.updateAnswer(questions[activeStep + 1].id, questions[activeStep + 1].answer);
        setTextInput("");
      }, 20);
    } else {
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;      
      setTimeout(() => {
        setActiveStep(activeStep + 1);
      }, 500);
    } else {      
      setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
  }

  const updateTextQuestion = (answer) => {
    setTextInput(answer);
    // addCtx.updateTextAnswer(questions[activeStep + 1].id, answer);
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep]?.mandatory === "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep]?.type === "slider" && questions[activeStep]?.answer !== 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.type === "textarea" && questions[activeStep]?.answerTxt !== "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }
  
  const nextQuestionClass = () => {
    if(activeStep === 0) {
      return "";
    } else if(activeStep === 2) {
      return "all-set-btn nextbtn";
    } else return "nextbtn";
  }

  const nextArrowFunc = () => {
    if(activeStep === 0) {
      return nextArrow;
    }
    return nextArrowWhite;
  }

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} textAnswer={updateTextQuestion} currentValue={prevValue}/>
    );
  };

  // const getStepContent = (step) => {
  //   if(step === 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }
    
  // };

  const showNextButton = () => {
    if(activeStep === 2) {
      return <>
        You're all set!
      </>;
    } else if(activeStep === 1) {
      return <>
        Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" />
      </>;
    } else {
      return <>
        &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" />
      </>;
    }
  }


  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress  style={{'color': '#ffffff'}} size={60} /></div>
	}

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer"></div>

              <h1>MEASURING THE URGENCY FOR CHURCH HEALTH</h1>

              {/* <div className="spacer"></div> */}
              <div className="progress-indicator"><strong>{activeStep + 1}/{questions.length}</strong></div>

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  {showNextButton()}
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default AssessmentAdditional;