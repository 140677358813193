import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const Assessment = () => {

  let navigate              = useNavigate();
  const { id }              = useParams();
  const { windowWidth }     = useWindowDimensions();

  useEffect(() => {
    document.body.style.backgroundColor = "#122845";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();      
    } else {
      getQuestions();
    }

  }, []);

  const [searchParams, setSearchParams]            = useSearchParams();
  const [isLoading, setIsLoading]                  = useState(true);
  const [activeStep, setActiveStep]                = useState(0);
  const [prevValue, setPrevValue]                  = useState(0);
  const [nextValue, setNextValue]                  = useState(0);
  const [sections, setSections]                    = useState([]);
  const [questions, setQuestions]                  = useState([]);
  const [questionCount, setQuestionCount]          = useState(0);
  const [currentHash, setCurrentHash]              = useState(0);
  const [hasAdditional, setHasAdditional]          = useState(false);
  const [qs, setQs]                                = useState([]);
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
				setHasAdditional(data.hasAdditional);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  function getQuestions() {

    let clientId              = process.env.REACT_APP_CLIENT_ID;
    
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        console.log(data);
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        setHasAdditional(data.hasAdditional);
        setIsLoading(false);
			} else {
				// setOpenSnackbar(true);
			}
		});

  }

  const currentSectionName = sections?.find(section => section.id === questions[activeStep]?.sectionId)?.section;

  console.log(questions);
  console.log(sections);
  console.log(activeStep);

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				if(hasAdditional) {
          navigate("/assessment-additional/" + data.id, { replace: true });
        } else {
          if(data.member == true) {
            navigate("/details/" + data.id + '?member=' + data.memberId, { replace: true });
          } else {
            navigate("/details/" + data.id, { replace: true });
          }
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const prevQuestion = () => {
    if(activeStep < 1) {
      navigate("/start", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } else if(currentHash != 0 && hasAdditional) {
      if(searchParams.get("member") != null) {
        navigate("/assessment-additional/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/assessment-additional/" + currentHash, { replace: true });
      }
    } else {
      if(searchParams.get("member") != null) {
        navigate("/details/" + currentHash + '?member=' + searchParams.get("member"), { replace: true });
      } else {
        navigate("/details/" + currentHash, { replace: true });
      }
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;
      setTimeout(() => {setActiveStep(activeStep + 1);}, 500);
    } else {
      questions[activeStep].answer = answer;
      setTimeout(() => { completeAssessment(); }, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  const nextDisabled = (answer) => {
    if(questions[activeStep].mandatory == "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }
  
  const questionsPerSection = getQuestionsPerSection();
  // console.log("questionsPerSection", questionsPerSection);

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} />
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              <div className="spacer"></div>

              {windowWidth > 830 ? <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} /> : <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} /> }
              {windowWidth <= 830 && <p className="current-section">{currentSectionName}</p>}
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer mobile-spacer"></div>
              <div className="spacer mobile-spacer"></div>
              
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div> */}

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button onClick={nextQuestion} style={nextDisabled()}>
                  &nbsp;&nbsp;<img src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;
